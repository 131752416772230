<template>
  <div class="full-height full-width">
    <template v-if="!previewSupported">
      {{ $t("_RSFAP_SHARED_FILE_VIEWER_NOT_SUPPORTED_FILE_TYPE") }}
    </template>
    <template v-else-if="error">
      {{ $t("_RSFAP_SHARED_ERROR") }}
    </template>
    <div v-else class="row items-center justify-center full-height full-width">
      <div v-if="loading" class="row justify-center full-height items-center">
        <q-spinner size="50px" />
      </div>
      <img v-else-if="fileType === 'IMAGE' && fileUrl" :src="fileUrl" />
      <object v-else-if="fileType === 'PDF' && fileUrl" :data="fileUrl" class="full-height full-width" />
      <textarea v-else-if="fileType === 'TEXT' && txtContent" v-model="txtContent" class="full-height full-width" readonly />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"

import * as fileInfoService from "@/services/files.js"

const props = defineProps({
  filePath: String,
  fileSrc: String,
  fileData: Blob,
  customFileType: String
})

const supportedFileTypes = ["IMAGE", "PDF", "TEXT"]

const fileType = ref("")
const fileUrl = ref("")
const txtContent = ref("")
const previewSupported = ref(false)
const loading = ref(false)
const error = ref(false)

const updateFile = async () => {
  error.value = false
  fileType.value = props.customFileType || fileInfoService.getTypeForFile(props.filePath)

  if (!supportedFileTypes.includes(fileType.value)) {
    previewSupported.value = false
    return
  }

  previewSupported.value = true
  loading.value = true

  try {
    if (props.fileData) {
      setFileDataFromFileInput(props.fileData)
    } else {
      const res = await fileInfoService.downloadFile(props.fileSrc)
      setFileDataFromArrayBuffer(res)
    }
  } catch (err) {
    error.value = true
  } finally {
    loading.value = false
  }
}

const setFileDataFromFileInput = (fileData) => {
  switch (fileType.value) {
    case "PDF":
    case "IMAGE":
      fileUrl.value = URL.createObjectURL(fileData)
      break
    case "TEXT":
      readTextFileData(fileData)
      break
  }
}

const readTextFileData = (data) => {
  const reader = new FileReader()

  reader.onload = () => {
    txtContent.value = reader.result
  }
  reader.readAsText(data)
}

const setFileDataFromArrayBuffer = (data) => {
  switch (fileType.value) {
    case "PDF":
      fileUrl.value = URL.createObjectURL(
        new Blob([data], {
          type: "application/pdf"
        })
      )
      break
    case "IMAGE":
      fileUrl.value = URL.createObjectURL(new Blob([data]))
      break
    case "TEXT":
      readTextFileData(new Blob([data]))
      break
  }
}

watch(
  () => [props.filePath, props.fileSrc, props.fileData, props.customFileType],
  () => {
    updateFile()
  },
  { immediate: true }
)
</script>
