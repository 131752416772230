import { ref } from "vue"
import { defineStore } from "pinia"

import { Quasar } from "quasar"

import * as translationsApi from "@/api/translationsApi.js"

import { useGlobalStore } from "@/stores/global.js"
import { useModuleDataStore } from "@/stores/moduleData.js"

import i18n from "@/plugins/i18n.js"

const quasarLangList = import.meta.glob("../../node_modules/quasar/lang/(de|fr|it|en-US|es).js")

export const useTranslationsStore = defineStore(
  "translations",
  () => {
    const messages = ref({})
    const currentLanguage = ref("fr")
    const changingLanguage = ref(false)
    const messagesLoaded = ref(false)

    const globalStore = useGlobalStore()

    const escapeSpecialCharacters = (string) => string.replace(/[@$|]/g, (match) => `{'${match}'}`)

    const fetchTranslations = async () => {
      try {
        checkCurrentLanguageSupport()
        const data = await translationsApi.translations({ locale: currentLanguage.value })
        Object.keys(data).forEach((k) => (messages.value[k] = escapeSpecialCharacters(data[k].VALUE)))

        i18n.global.locale.value = currentLanguage.value
        document.querySelector("html").setAttribute("lang", currentLanguage.value)
        i18n.global.setLocaleMessage(currentLanguage.value, messages.value)
        await setQuasarLanguage(currentLanguage.value)
        messagesLoaded.value = true
      } catch (err) {
        console.error(err)
      }
    }

    const fetchAdminTranslations = async () => {
      try {
        const data = await translationsApi.adminTranslations({
          locale: currentLanguage.value
        })
        return data
      } catch (err) {
        console.error(err)
      }
    }

    const setAdminTranslations = async (translations) => {
      try {
        const data = await translationsApi.setLocaleTranslations({
          locale: currentLanguage.value,
          translations
        })
        return data
      } catch (err) {
        console.error(err)
      }
    }

    const langsMap = {
      fr: "fr",
      en: "en-US",
      es: "es",
      it: "it",
      de: "de"
    }

    const setQuasarLanguage = async (language) => {
      const langModulePath = `../../node_modules/quasar/lang/${langsMap[language]}.js`
      try {
        const langModule = await quasarLangList[langModulePath]()
        Quasar.lang.set(langModule.default)
      } catch (error) {
        console.error(`Failed to load Quasar language module: ${error}`)
      }
    }

    const setLanguage = async (language) => {
      if (currentLanguage.value === language || changingLanguage.value) {
        return
      }

      try {
        changingLanguage.value = true
        currentLanguage.value = language
        await fetchTranslations()
      } finally {
        changingLanguage.value = false
      }
    }

    const isLanguageSupported = (language) => {
      const moduleStore = useModuleDataStore()
      if (globalStore.globalSettings.enabledLanguages.includes(language) && moduleStore.moduleConfig.supportedLanguages.includes(language)) {
        return language
      }
      return null
    }

    const getLanguageFromBrowserSettings = () => {
      let lang = navigator.language || navigator.userLanguage

      if (lang?.includes("-")) {
        lang = lang.split("-")[0]
      }
      return isLanguageSupported(lang)
    }

    const getLanguageFromDefaultAppLanguageSetting = () => isLanguageSupported(globalStore.globalSettings.defaultLanguage)

    const getFallbackLanguage = () =>
      useModuleDataStore().moduleConfig.value.supportedLanguages.find((language) => globalStore.globalSettings.enabledLanguages.includes(language)) || "fr"

    const checkCurrentLanguageSupport = () => {
      if (isLanguageSupported(currentLanguage.value)) {
        return
      }
      currentLanguage.value = getLanguageFromBrowserSettings() || getLanguageFromDefaultAppLanguageSetting() || getFallbackLanguage()
    }

    return {
      currentLanguage,
      messages,
      messagesLoaded,
      fetchTranslations,
      setLanguage,
      fetchAdminTranslations,
      setAdminTranslations,
      changingLanguage,
      setQuasarLanguage,
      getFallbackLanguage,
      checkCurrentLanguageSupport,
      getLanguageFromDefaultAppLanguageSetting,
      getLanguageFromBrowserSettings
    }
  },
  {
    persist: {
      paths: ["currentLanguage"],
      afterRestore: ({ store }) => {
        store.setQuasarLanguage(store.currentLanguage)
      }
    }
  }
)
