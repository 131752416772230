import { computed } from "vue"

export function useSelection(items, key = "selected") {
  const allSelected = computed({
    get: () => items.value.length > 0 && items.value.every((item) => item[key]),
    set: (value) => {
      items.value.forEach((item) => {
        item[key] = value
      })
    }
  })

  return {
    allSelected
  }
}
