<template>
  <q-btn :color="color" :style="styles" :text-color="computedTextColor">
    <slot />
    <q-tooltip v-if="$attrs.title" class="bg-primary">
      {{ $attrs.title }}
    </q-tooltip>
  </q-btn>
</template>

<script setup>
import { computed, useAttrs } from "vue"

import { useRoute } from "vue-router"

const route = useRoute()

const props = defineProps({
  styles: {
    type: Object,
    default: () => null
  },
  textColor: {
    type: String,
    default: null
  },
  routeMatchAccentColor: {
    type: String,
    default: null
  }
})

const attrs = useAttrs()

const color = computed(() => (props.styles ? null : "dark"))

const routeMatchesToProp = computed(() => {
  if (route.path === attrs.to) {
    return true
  }
  const filteredParams = Object.fromEntries(Object.entries(route.params || {}).filter(([_, value]) => value !== ""))
  return route.name === attrs.to?.name && JSON.stringify(filteredParams) === JSON.stringify(attrs.to?.params || {})
})

const computedTextColor = computed(() => {
  if (props.routeMatchAccentColor && routeMatchesToProp.value) {
    return props.routeMatchAccentColor
  }

  return props.textColor
})
</script>
